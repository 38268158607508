import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts';
import axios from 'axios';
import config from './helpers/config';
import LottieVuePlayer from '@lottiefiles/vue-lottie-player';
import './registerServiceWorker';
import { BASE_URL } from './helpers/constants';

Vue.use(LottieVuePlayer);

Vue.config.productionTip = false;

const $eventBus = new Vue();
Vue.prototype.$eventBus = $eventBus;
const $isOffline = Vue.observable({ value: false });
Vue.prototype.$isOffline = $isOffline;

Vue.component('apexchart', VueApexCharts);

window.axios = axios.create({
	baseURL: BASE_URL,
});
window.axios.interceptors.response.use(
	function (response) {
		if (response.headers['service-worker']) {
			$isOffline.value = true;
		} else {
			if ($isOffline.value) router.go();
		}
		return response;
	},

	function (error) {
		if (error.code === 'ERR_NETWORK' || error.code === 'ETIMEDOUT' || error.code === 'ECONNABORTED') {
			$isOffline.value = true;
		}
		throw error;
	},
);

let loggedIn;

// log in from the data in localstorage
if ('auth' in localStorage && 'active-user' in localStorage) {
	store.state.login.users = JSON.parse(localStorage.getItem('auth')) || [];
	const activeUser = JSON.parse(localStorage.getItem('active-user'));

	loggedIn = activeUser;

	if (activeUser) {
		store
			.dispatch('login/login', {
				userName: activeUser.userName,
				password: activeUser.password,
				centerId: activeUser.centerId,
			})
			.then(() => {
				$eventBus.$emit('show-snackbar', false, 'اهلا وسهلا');
			})
			.catch((err) => {
				if (err.response?.status === 401) {
					store.dispatch('login/logout', { user: activeUser });
					if (router.currentRoute.name !== 'login') router.replace({ name: 'login' });
					$eventBus.$emit('show-snackbar', true, 'تم تعديل معلومات الحساب او حذفه');
				} else {
					$eventBus.$emit('show-snackbar', true, 'حدث خطأ ما. اعد المحاولة لاحقا');
				}
			})
			.finally(() => {
				$eventBus.$emit('loader', false);
			});
	}
}

router.beforeEach((to, from, next) => {
	if (from.name) loggedIn = store.state.login.activeUser;

	if (!loggedIn && to.name !== 'login' && to.name !== 'accounts') {
		if (store.state.login.users.length) next({ name: 'accounts' });
		else next({ name: 'login' });
	} else {
		if (to.name === 'funds' && !config.showFunds) {
			next({ name: 'home' });
		}
		next();
	}
});

// notification inits
require('@/notifications/inits.js');

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
