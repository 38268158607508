module.exports = {
	colors: {
		main: '#0C68B3',
		sec: '#F79227',
		alt: '#0C68B3',
	},

	showFunds: false,

	about: {
		mobiles: ['0932722555'],
		phones: ['0212677659', '0212663656', '0212663646'],
		address: 'الشهباء الجديدة شارع السنديان',
		data: [0, 0],
		email: null,
		facebook: 'https://www.facebook.com/ShamsAlAssil98',
		privacy: 'https://shamsalassil.edunixsystem.com/privacy-policy/',
		details: [
			{
				title: 'مدرسة شمس الأصيل الخاصة بحلب',
				content: [
					'تأسست عام 1999 تحت عنوان ((مدرسة وطنية بمواصفات عالمية)) بناءً على قناعتنا بأن التعليم الصحيح أداة فاعلة في مسيرة التنمية المستدامة الاجتماعية والاقتصادية لبناء الفرد القادر على تحقيق تطلعات الأسرة والمجتمع. تم إنشاء هذه المدرسة وفق أرقى المقاييس العالمية لتكون نواة المدارس  الخضراء في المدن صديقة الطفولة في الشرق الأوسط كما نأمل ونسعى.',
				],
			},
			{
				title: 'رؤيتنا:',
				content: [
					'المدرسة عمل تشاركي مع الأهالي يؤمن بيئة صالحة لنمو الطالب اجتماعيا	وعلمياُ وثقافياً وبدنياً. لفئة عمرية من ٣ - ١٨ سنة ليكون " نور الحاضر وأمل المستقبل".',
				],
			},
			{
				title: 'رسالتنا:',
				content: [
					`نسعى لاستبدال تحصيل المعرفة لأهداف آنية إلى غرس حب العلم والتعليم   
					والقراءة ليتمكن أبناؤنا الطلاب من الوصول إلى المعرفة وإنتاجها بأنفسهم.
					كما نعمل على بناء شخصياتهم ودعم القيم الإنسانية وتعزيز روح الانفتاح
				   الواعي لديهم والإيمان بقيم العمل والإنتاج وإشاعة مبدأ الحوار وروح 
				   التسامح والتعامل الراقي والرشيد مع التطورات التكنولوجية وتطبيقاتها`,
				],
			},
			{
				title: '',
				content: [
					`إن التفكير السليم والتخطيط المحكم والعمل الدؤوب هو ما أوصلنا لما نحن عليه اليوم.
					مدرسة متميزة إدارياً وتعليمياً 
					الحمد لله الذي "علم بالقلم * علم الإنسان مالم يعلم "
					الحمد لله الذي سدد خطانا.
					الحمد لله الذي حقق مبتغانا.`,
				],
			},
		],
	},

	pwa: {
		name: 'مدرسة شمس الأصيل',
		shortName: 'مدرسة شمس الأصيل',
		description: 'مدرسة شمس الأصيل الخاصة بحلب',
	},
};
