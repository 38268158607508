import * as types from '../mutations';

export default {
	namespaced: true,

	state: {
		marks: [],
	},

	actions: {
		fetchAll({ rootState, commit, state }) {
			return state.marks.length
				? state.marks
				: window.axios
					.get(
						`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/marks`,
					)
					.then((res) => {
						commit(types.MARKS, res.data);
						return res.data;
					});
		},
		fetchMarkBySubject({ rootState, state }, { subjectId }) {
			if (state.marks.length) {
				var markSubject = state.marks.find((e) => e.SubjectId === subjectId);
			}
			return markSubject
				? markSubject.Marks
				: window.axios
					.get(
						`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/subject-marks/${subjectId}`,
					)
					.then((res) => {
						return res.data;
					});
		},
		fetchSubjectAverage({ rootState }, { subjectId }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/monthly-subject-average/${subjectId}`,
				)
				.then((res) => {
					return res?.data;
				});
		},
		clear({ commit }) {
			commit('CLEAR');
		},
	},

	mutations: {
		[types.MARKS](state, marks) {
			state.marks = marks;
		},
		CLEAR(state) {
			state.marks = [];
		},
	},
};
